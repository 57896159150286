*{
  margin: 0;
  padding: 0;
  font-family: "Times New Roman",'Encode Sans Expanded', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #f7f7f7;
}

.pagina{
  display: flex;
  max-width:100vw ;
  width: 100%;
  min-height:100vh;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  /* This should be dynamic, its not responsive while the navbar is */
  margin-top:68.5px;  
  position: absolute;
  overflow-x: hidden;
}

a{
  color:#1991eb;
  text-decoration: none;
}
/*left panel css */
#leftPanel{
 /* width:30%; */
 max-width: 300px;
 max-height: 100%;
 min-height: 100%;
 position: fixed;
 left: 0;
 overflow: auto;
 background-color: #ffffff;
}

.filter-headers{
  width: 100%;
  height: 70px;
  background-color: #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
    font-weight: 300;
}
.filter-headers-content{
  width: 90%;
  height: 100%;
  display:flex;
  align-items: center;
  flex-direction: row;
  gap: 2.5rem;
}
.filter-headers-content >h2{
  font-size: 30px;
  font-family: sans-serif;
  font-weight: 300;
}
.filter-sections{
  width: 100%;
  height: 50px;
  background-color: #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 18px;
}
.seccion{
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.seccion >span{
  border-radius: 10px 10px 0px 0px;
  color:black
}
.seccion:hover{
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: #d9dbdf;
}
.seccion.active {
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}
.seccion.active >span{
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  color:#1991eb;
}
.seccion >span:hover{
  border-radius: 10px 10px 0px 0px;
  
}

#leftPanelContent{
 width:100%;
 height: 100%;
 margin: 40px 0px;
 display: flex;
 align-items: center;
 justify-content: center;
 color: #5d6a7e;
}

.droppableFilter{
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(99, 98, 98, 0.178);
  justify-content: center;
  cursor: pointer;
}
.droppableFilterInside{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 24px;
}
.droppableFilter:hover{
  background-color: rgba(99, 98, 98, 0.178);
}
.droppableFilterContent{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f9fb;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(99, 98, 98, 0.178);
}
.droppableFilterContentWrapper{
  width: 90%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}
.droppableFilterContentWrapper >input, select{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px 0px;
  margin: 10px 0;
  padding: 4px 10px;
}
.droppableFilterContentWrapper >input[type=date]{
  color:#5d6a7e
}
.droppableFilterContentWrapper >select{
  color:#5d6a7e;
  appearance: none; /* Hide default arrow in Firefox */
  -webkit-appearance: none; /* Hide default arrow in Webkit browsers */
  -moz-appearance: none; /* Hide default arrow in older Firefox versions */
  background-image: url('images/down_arrow.svg');
  background-size: 8px; /* Set the size of your custom arrow icon */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position the arrow icon to the right */
  padding-right: 20px; /* Add padding to prevent text from overlapping with the arrow */
}
.checkboxStyle{
  appearance: none; /* Hide the default checkbox */
  -webkit-appearance: none; /* Hide the default checkbox in Webkit browsers */
  width: 15px; /* Set the width of the custom checkbox */
  height: 15px; /* Set the height of the custom checkbox */
  background-color: #ffffff; /* Set the background color to blue */
  border: 1px solid #5d6a7e; /* Add a border with the same color as the background */
  border-radius: 2px; /* Optional: Add rounded corners to the checkbox */
  vertical-align: middle;
  position: relative;
  margin: 0.4rem 0;
}

.checkboxStyle:checked {
   /* Change the border color when the checkbox is checked */
   border: none
}
.checkboxStyle:checked::before {
  content: ''; /* Clear the default content */
  background-image: url('images/check.svg'); /* URL to your SVG checkmark */
  background-size: cover; /* Scale the SVG to cover the entire area */
  width: 100%; /* Set the width to 100% */
  height: 100%; /* Set the height to 100% */
  position: absolute;
  top: 0;
  left: 0;
}
.universidadesCheckBox{
  margin-top: 4px;
}

.chk-label {
  font-size: 1.1rem;
  width: calc(100% - 20px);
  display: inline-block;
  user-select: none;
}

#metadata-filter{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#semantic-filter{
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
}
.search{
  margin-top: 40px;
  margin-bottom: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  
}
.search >button{
  height: 30px;
  width: 130px;
  color:white;
  background-color: #1991eb;
  border: none;
  cursor: pointer;
}
.search >button:hover{
  background-color: #146db1;
}
.spacer{
  height: 20px;
}
.semantic_section{
  display: flex;
  flex-direction: column;
}
.semantic_section >input{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px 0px;
  margin: 10px 0;
  padding: 4px 10px;
}
/*right panel css */
#rightPanel{
  width:100%;
  min-height: 100%;
  display:flex;
  /* margin-left:30px; */
  padding-left: 10px;
  background-color: #f7f7f7;
  justify-content: center;
}
/* 
@media (min-width: 600px)
{
  #rightPanel {
    padding-left:300px;
  }
} */

.title{
  max-width: 800px;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.rightTitle:hover {
  cursor: pointer;
}
.titlecolor{
  color: #1991eb;
}
.titleDescription{
  height: 100%;
  /* margin-left: 15px; */
  font-size: 25px;
  font-weight: normal;
}
#rightPanelContent{
  width:80%;
  
  margin-top: 20px;
  position: relative;
}
#memories {
  border-collapse: collapse;
  width: 100%;
}

#memories td, #memories th {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 150px;
  text-align: center; 
  
}

#memories tr:nth-child(even){background-color: #f2f2f2;}

#memories tr:hover {background-color: #ddd;}

#memories th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #2d5a81;
  color: white;
  cursor: pointer;
  
}
#memories th.sorting_active_desc:after{
  content: url("./images/sort_desc.png");
}
#memories th.sorting_active_asc:after{
  content: url("./images/sort_asc.png");
}
#memories th.sorting_disabled:after{
  content: url("./images/sort_both.png");
}

.no_sortable::after {
  content: none !important;
}

#memories-container {
  width: 100%;
  overflow-y: scroll; 
}

#tableContainer {
  position: relative;
  height: auto;
  /* overflow-y: scroll; */
}
#procesando {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff9d;
  z-index: 10;
}
#error {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff9d;
  z-index: 11;
}
.paginationWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.more-info {
  color:black; 
  text-decoration:underline dotted;
}
.more-info:hover {
  cursor: pointer;
}

.highlighted-word {
  font-weight: bold;
}

.highlight-container {
	background-color: #e1e1e19e;
	text-overflow: clip;
	text-wrap: wrap;
	overflow-y: auto;
	height: 12vh;
	margin-bottom: 2rem;
	margin-top: 2rem;
	padding: 1rem;
	border-radius: 10px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  border: 2px solid #000;
  background-color: white;
  box-shadow: 0px 2px 2px black;
  padding:1rem;
}

.drawer-button {
  position: absolute;
  left: 1rem;
}

.ellipsis-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.memory-opened {
	filter: drop-shadow(20px 0px 40px gray);
	background-color: white;
}

.MuiDrawer-paper {
    min-width: 2px;
    position: relative !important;
    top:0px !important;
    width: 300px !important;
}


@media (max-width: 600px)
{
  .MuiDrawer-paper {
    top:56px !important;
  }
} 

@media (min-width:600px) and (max-width: 1200px)
{
  .MuiDrawer-paper {
    top:65px !important;
  }
} 

.MuiAppBar-root {
  position: fixed !important;
  top:0;
  z-index: 10000;
}

.aboutus {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
}

.aboutus-banner {
  background-color: #2f70a1;
  position: relative;
  height: 20rem;
  width: 100%;
  top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.about-banner-title {
  color: #cde9ff;
}

.aboutus-text {
  color:black;
  padding-top: 2rem;
  padding: 2rem;
}

.aboutus-contact {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  flex-direction: column;
}

.aboutus-spacer {
  height: 2rem;
}

.reset-btn{
  height: 30px;
  width: 130px;
  color:white;
  background-color: #647c8f;
  border: none;
  cursor: pointer;
}

.reset-btn:hover{
  background-color: #516372;
}

.login-form-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0f0e0e6e;
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: center;
}

.login-form-container.open {
  display: flex;
}

*:has( * > .login-form-container.open) { 
  overflow: hidden !important;
}

.login-form {
  position: absolute;
  width: 25rem;
  height: 15rem;
  background-color: #eceaea;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.login-form > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form > form > .form-group label {
  display: inline-block;
  width: 100px;
}


.form-group {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: left;
}

.form-submit {
  min-width: 6rem;
  max-width: 12rem;
  color:white;
  background-color: #1991eb;
  border: none;
  cursor: pointer;
  padding: 0.3rem 0rem;
  margin-top: 1rem;
}
.form-submit:hover{
  background-color: #146db1;
}

.form-error {
  color:rgb(173, 38, 38);
}

.form-title {
  color: black;
}

.switch-group {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  justify-content: left;
  gap: 1rem;
}

.switch-group-item {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: baseline;
}

.switch-group-item-title {
  color: rgba(0, 0, 0, 1);
}

.switch-group-item-description {
  padding-top: 0.4rem;
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.6);
}

.queries-statistics-data-grid {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  
}

.statistics-page {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.status-gauge, .data-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.widget-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.linechart-with-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  color: #516372;
}